import $http from './index'

export function repair(rows,page,adviceType){
    return $http.get('/Advice/SearchAdviceGson',{
        params:{
            rows:rows,
            page:page,
            adviceType:adviceType
        }
    })
}

// 回复

export function postHuifu(id,solveContent){
    return $http.post('/Advice/ReplyAdvice',{
        id:id,
        solveContent:solveContent
    })
}


