import $http from './index'

export function complaint(rows,page,adviceType){
    return $http.get('/Advice/SearchAdviceGson',{
        params:{
            rows:rows,
            page:page,
            adviceType:adviceType
        }
    })
}

//发送未读  
 export  function sendUnread(id){
     return $http.post('/Advice/UpdateAdivceRead',{
         id
     })
 }